<template>
  <div class="pay">
    <div class="title">结账</div>
    <div class="top-card">
      <div>已下单的商品</div>
      <div class="top-item">
        <div style="display: flex; align-items: center">
          <el-image
            style="width: 80px; height: 80px"
            :src="adImg"
            fit="fit"
          ></el-image>
          <span style="margin-left: 10px">Shopee paid ads RM {{ money }}</span>
        </div>
        <div style="font-size: 14px; color: #666">商品价格：RM {{ money }}</div>
      </div>
    </div>
    <div class="top-card">
      <div>付款方式</div>
      <div class="pay-item">
        <div style="font-size: 15px; margin-bottom: 20px">选择选项</div>
        <div>
          <el-radio
            style="display: flex; align-items: center"
            v-model="radio"
            label="1"
          >
            <div style="display: flex; align-items: center">
              <el-image
                style="width: 70px; height: 70px"
                :src="wxImg"
                fit="fit"
              ></el-image>
              <div>微信支付</div>
            </div>
          </el-radio>
        </div>
      </div>
      <div
        style="
          border-top: 1px dashed #eee;
          border-bottom: 1px dashed #eee;
          padding: 30px;
          margin-top: 30px;
          display: flex;
          justify-content: right;
        "
      >
        <div style="font-size: 15px; color: #333">
          <div
            style="
              width: 200px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            商品价格：
            <span>RM {{ money }}</span>
          </div>
          <br />
          <div
            style="
              width: 200px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            付款总额：
            <span style="font-size: 20px; color: #ee4d2d">RM {{ money }}</span>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: right; padding: 20px">
        <div style="color: #666">
          商店名称：{{ shopName }}
          <br />
          <br />
          <el-button size="medium" @click="toBack">返回</el-button>
          <el-button size="medium" type="primary" @click="handlePay"
            >确认</el-button
          >
        </div>
      </div>
    </div>

    <div class="pay-over" v-if="payShow">
      <div style="display: flex; align-items: center" v-if="next == 1">
        <el-image
          style="width: 70px; height: 70px"
          :src="wxImg"
          fit="fit"
        ></el-image>
        正在支付中...
      </div>
      <div v-else style="display: flex; align-items: center">
        <i
          class="el-icon-success"
          style="font-size: 40px; color: #2cb34a; margin-right: 10px"
        ></i>
        支付成功
      </div>
    </div>
  </div>
</template>
<script>
import { recharge } from "@/api/marketing.js";
export default {
  data() {
    return {
      radio: "1",
      wxImg: require("@/assets/img/admin/wxpay.png"),
      adImg: require("@/assets/img/admin/ad.jpg"),
      shopName: "",
      money: 0,
      payShow: false,
      next: 1,
    };
  },
  created() {
    if (localStorage.CENTERINFO) {
      this.shopName = JSON.parse(localStorage.CENTERINFO).shop_info.shop_name;
    }
    if (this.$route.query.money) {
      this.money = this.$route.query.money;
    }
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    handlePay() {
      this.payShow = true;
      this.next = 1;
      recharge({
        money: this.money,
      }).then((res) => {
        let _this = this;
        // 支付中
        setTimeout(function () {
          // 支付成功
          _this.next = 2;
          setTimeout(function () {
            _this.$router.replace("advertising");
          }, 1000);
        }, 1000);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pay {
  width: 1400px;
  padding: 20px;
  margin: 0 auto;
  .title {
    font-size: 20px;
  }
  .top-card {
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.01);
    margin-top: 20px;
    border-radius: 4px;
    .top-item {
      border: 1px solid #eee;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
    .pay-item {
      border: 1px solid #eee;
      background: #fafafa;
      padding: 20px;
      margin-top: 10px;
    }
  }
  .pay-over {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
</style>